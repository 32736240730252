import { gql } from '@apollo/client';

export const GET_EXAM_FOR_RESUBMIT = gql`
  query GetExamForResubmit($examId: MongoID!) {
    exam(filter: { _id: $examId }) {
      _id
      filters
      user {
        firebaseId
        institution {
          _id
        }
      }
    }
  }
`;
