import { CDN_EXPIRATION_LOCAL_STORAGE } from 'constants/api';
import { logError } from 'utils/logError';
import { axiosRestApi } from './axiosRestApi';

// backend cdn signed cookie request
export const callGetCdnSignedCookie = () => {
  // cdn signed cookie base url
  axiosRestApi
    .get<{ status: string; expirationTime: number }>('/getCdnSignedCookie')
    .then((response): void => {
      localStorage.setItem(
        CDN_EXPIRATION_LOCAL_STORAGE,
        response.data.expirationTime.toString(),
      );
    })
    .catch((error: unknown) => {
      logError({ error, ignoreSentry: true });
    });
};
