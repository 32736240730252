import { PATIENTS_VIEW_ROUTE, STUDY_MANAGER_ROUTE } from 'routes/constants';

export const PAGINATION_PAGES = [
  // remove the last character to match the pagination page route
  PATIENTS_VIEW_ROUTE.slice(0, -1),
  STUDY_MANAGER_ROUTE.slice(0, -1),
] as const;

export const reloadPaginationPages = () => {
  const currentPage = window.location.pathname;
  const shouldReloadPage = PAGINATION_PAGES.some((page) =>
    currentPage.startsWith(page),
  );
  if (shouldReloadPage) {
    window.location.reload();
  }
};
