import { AxiosError } from 'axios';
import { IFeedbackModal } from 'components/layout/FeedbackModal';

export const tooManyRequestsFeedbackModalProps: IFeedbackModal = {
  type: 'error',
  title: 'Too many requests',
  message:
    "We're sorry, but you've sent too many requests in a short period. Please try again later.",
};

export const getAxiosTooManyRequestsFeedbackModalProps = (error: unknown) => {
  if (error instanceof AxiosError && error.response?.status === 429) {
    return tooManyRequestsFeedbackModalProps;
  }

  return null;
};
