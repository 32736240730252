import { gql, useLazyQuery } from '@apollo/client';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  debounce,
} from '@mui/material';
import { displayToast } from 'components/system/Toast';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PatientDocument } from 'types/mongoose-types';
import { logError } from 'utils/logError';
import { sanitizeSearchInput } from './helpers/sanitizeSearchInput';

export interface IPatientSearchPatients {
  _id: PatientDocument['_id'];
  fullIdentification: string;
}

interface IPatientSearch {
  patientsSearch: {
    patients: IPatientSearchPatients[];
    hasMoreResults: boolean;
  };
}

interface IPatientSearchVariables {
  search: string;
}

export const PATIENTS_SEARCH = gql`
  query PatientSearch($search: String!) {
    patientsSearch(search: $search) {
      patients {
        _id
        fullIdentification
      }
      hasMoreResults
    }
  }
`;

const MIN_CHARACTERS_TO_SEARCH = 1;
const DEBOUNCE_TIME = 700;

export const useSearchPatients = (debounceTime: number = DEBOUNCE_TIME) => {
  const navigate = useNavigate();
  const [hideOptions, setHideOptions] = useState(true);
  const [inputValue, setInputValue] = useState<string>('');
  const [debounceInProgress, setDebounceInProgress] = useState(false);

  const [
    loadSearchResults,
    { loading: loadingSearch, data, error, previousData },
  ] = useLazyQuery<IPatientSearch, IPatientSearchVariables>(PATIENTS_SEARCH, {
    notifyOnNetworkStatusChange: true,
  });

  const fetchSearchResults = useMemo(
    () =>
      debounce(async (search: string) => {
        try {
          await loadSearchResults({ variables: { search } });
        } catch (searchError) {
          logError({ error: searchError });
        } finally {
          setDebounceInProgress(false);
          setHideOptions(false);
        }
      }, debounceTime),
    [debounceTime, loadSearchResults],
  );

  const handleInputChange = async (
    _event: SyntheticEvent,
    currentInputValue: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (currentInputValue === '') {
      setHideOptions(true);
    }
    if (reason === 'reset') {
      setHideOptions(true);
      setInputValue('');
      return;
    }
    setInputValue(currentInputValue);
    if (currentInputValue.length >= MIN_CHARACTERS_TO_SEARCH) {
      setDebounceInProgress(true);
      await fetchSearchResults(sanitizeSearchInput(currentInputValue));
    }
  };

  const handleOnChange = (
    event: SyntheticEvent,
    currentValue: IPatientSearchPatients | null,
    reason: AutocompleteChangeReason,
  ) => {
    event.preventDefault();
    if (reason === 'selectOption' && currentValue) {
      navigate(`/app/patients-view/${currentValue._id.toString()}`);
    }
  };

  useEffect(() => {
    if (error) {
      displayToast({
        type: 'error',
        title: 'Search Error',
        description: 'Something went wrong. Please try again later.',
      });
    }
  }, [error]);

  const loading = useMemo(
    () => loadingSearch || debounceInProgress,
    [loadingSearch, debounceInProgress],
  );

  // If the data is null, use the previous data to prevent flickering
  const dataMemo = useMemo(() => data ?? previousData, [data, previousData]);

  return {
    data: dataMemo,
    handleOnChange,
    handleInputChange,
    hideOptions,
    inputValue,
    loading,
  };
};
