import axios, { AxiosError } from 'axios';

import { API_URL, CF_AUTH_COOKIE } from 'constants/api';

if (process.env.REACT_APP_CF_AUTHORIZATION) {
  // set cloudflare authorization cookie
  document.cookie = `${CF_AUTH_COOKIE}=${process.env.REACT_APP_CF_AUTHORIZATION}`;
}

// backend cloudflare auth validation request
export const getCloudflareAuth = async () => {
  // axios instance with retry interceptor for cloudflare auth request
  const cloudflareAuthRequest = axios.create({
    baseURL: `${API_URL}/api/login`,
    withCredentials: true,
  });

  let retryCounter = 5;
  const retryDelay = 2000;

  cloudflareAuthRequest.interceptors.response.use(
    undefined,
    async (err: AxiosError) => {
      const { config } = err;
      const isTooManyRequestsError = err.response?.status === 429;

      if (!config || !retryCounter || isTooManyRequestsError) {
        return Promise.reject(err);
      }
      retryCounter -= 1;

      const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, retryDelay);
      });
      return delayRetryRequest.then(() => cloudflareAuthRequest(config));
    },
  );

  try {
    const authResponse = await cloudflareAuthRequest.post<{
      status: string;
      expirationDate: number;
      firebaseId: string;
    }>('/');
    if (authResponse.data) {
      const { firebaseId, expirationDate } = authResponse.data;
      return { firebaseId, expirationDate };
    } else {
      throw new Error(JSON.stringify(authResponse));
    }
  } catch (err) {
    if (err instanceof Error || err instanceof AxiosError) {
      throw err;
    } else {
      throw new Error('Unexpected error');
    }
  }
};
