import { AxiosError } from 'axios';
import { useState } from 'react';

import { IFeedbackModal } from 'components/layout/FeedbackModal';
import { axiosRestApi } from 'config/axiosRestApi';
import { FirebaseErrorCodeEnum } from 'constants/firebase';
import { useFeedbackModal } from 'providers';
import {
  getAxiosTooManyRequestsFeedbackModalProps,
  tooManyRequestsFeedbackModalProps,
} from 'utils/getAxiosTooManyRequestsFeedbackModalProps';
import { logError } from 'utils/logError';

const DEFAULT_ERROR_PROPS: IFeedbackModal = {
  title: 'Authentication Error',
  message: 'Invalid Credentials, please try again.',
  type: 'error',
};

const EMAIL_VERIFICATION_ERROR_PROPS: IFeedbackModal = {
  type: 'error',
  title: 'Error sending email verification',
  message: 'Please try again later',
};

export const useAuthModalError = () => {
  const { openFeedbackModal } = useFeedbackModal();
  const [loading, setLoading] = useState(false);

  const sendEmailVerification = async (email: string) => {
    try {
      await axiosRestApi.post('/resend-verification', {
        email,
      });
      openFeedbackModal({
        type: 'success',
        title: 'Email Sent',
        message: 'Please check your email to activate your account',
      });
    } catch (error: unknown) {
      logError({ error });

      const modalProps =
        getAxiosTooManyRequestsFeedbackModalProps(error) ??
        EMAIL_VERIFICATION_ERROR_PROPS;

      openFeedbackModal(modalProps);
    } finally {
      setLoading(false);
    }
  };

  const displayAuthErrorModal = (error: unknown, email?: string): void => {
    if (error instanceof AxiosError) {
      if (error?.response?.status === 429) {
        openFeedbackModal(tooManyRequestsFeedbackModalProps);
        return;
      }

      if (error?.response?.status === 500) {
        openFeedbackModal({
          type: 'error',
          title: 'Internal Server Error',
          message: 'Please try again.',
        });
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      switch (error.response?.data?.code) {
        case FirebaseErrorCodeEnum.RECENT_SIGN_IN_REQUIRED:
          openFeedbackModal({
            type: 'error',
            title: 'Recent sign-in required',
            message: 'Please sign in again.',
          });
          return;
        case FirebaseErrorCodeEnum.UNAUTHORIZED:
          openFeedbackModal({
            type: 'error',
            title: 'Unauthorized',
            message: 'You do not have authorization to perform this action.',
          });
          return;
        case FirebaseErrorCodeEnum.EMAIL_NOT_VERIFIED:
          openFeedbackModal({
            type: 'error',
            title: 'Email Verification',
            message:
              'You need to verify your email before logging in. Please check your inbox.',
            submitBtn: {
              label: 'Resend Email',
              onClick: () => {
                setLoading(true);
                if (!email) {
                  logError({
                    error: new Error(
                      'Email is required to resend verification email',
                    ),
                  });
                  return;
                }
                void sendEmailVerification(email);
              },
            },
          });
          return;

        default:
          openFeedbackModal(DEFAULT_ERROR_PROPS);
          return;
      }
    }

    return openFeedbackModal(DEFAULT_ERROR_PROPS);
  };

  return { displayAuthErrorModal, loading };
};
