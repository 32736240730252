import { axiosRestApi } from 'config/axiosRestApi';
import { useSession } from 'providers';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { logError } from 'utils/logError';
import useYupValidationResolver from 'utils/useYupValidationResolver';
import { ISignInTabPanel, ISignInValues } from '../auth.types';
import { useAuthModalError } from '../hooks/useAuthModalError';
import { validationSchema } from './signIn.validation';

export const useSignIn = ({ handleCloseModal }: ISignInTabPanel) => {
  const [isLoading, setIsLoading] = useState(false);
  const { displayAuthErrorModal, loading } = useAuthModalError();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<ISignInValues>({
    mode: 'onBlur',
    defaultValues: { email: '', password: '' },
    resolver: useYupValidationResolver(validationSchema),
  });
  const { updateFirebaseId, updateExpiresIn } = useSession();

  const onSubmit = async (values: ISignInValues) => {
    const { email, password } = values;
    setIsLoading(true);
    try {
      const { data } = await axiosRestApi.post<{
        status: string;
        expirationDate: number;
        firebaseId: string;
      }>('/login', {
        email,
        password,
      });

      updateExpiresIn(data.expirationDate.toString());
      updateFirebaseId(data.firebaseId);
      handleCloseModal();
    } catch (error) {
      displayAuthErrorModal(error, email);
      logError({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const formHandleSubmit = handleSubmit(onSubmit);

  return {
    isLoading: isLoading || loading,
    formHandleSubmit,
    errors,
    isValid,
    register,
    setValue,
  };
};
