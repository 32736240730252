import { gql } from '@apollo/client';

export const UPDATE_EXAM = gql`
  mutation Mutation(
    $updateExamId: MongoID!
    $updateExamRecord: UpdateByIdExamInput!
  ) {
    updateExam(_id: $updateExamId, record: $updateExamRecord) {
      record {
        _id
        technology
        device
        examDate
        physician
        clinicalIndication
        status
        filters
        priority
        user {
          _id
          institution {
            _id
          }
        }
        media {
          fullVideo
          highlights
        }
        medicalNotes {
          _id
          content
          user {
            _id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_EXAM_STATUS = gql`
  query GetExamStatus($examId: MongoID!) {
    exam(filter: { _id: $examId }) {
      status
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers {
    users {
      _id
      firstName
      lastName
      avatar
    }
  }
`;
