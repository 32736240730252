import {
  Autocomplete,
  Box,
  CircularProgress,
  InputBase,
  Paper,
  PaperProps,
  styled,
} from '@mui/material';

import { ListLoadingOverlay } from 'utils/ListLoadingOverlay';
import { useSearchPatients } from './Search.logic';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.subtitle.main,
  '& .MuiInputBase-input': {
    color: theme.palette.subtitle.main,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Search = ({
  // used for testing purposes
  debounceTime,
}: {
  debounceTime?: number;
}) => {
  const {
    data,
    loading,
    hideOptions,
    inputValue,
    handleInputChange,
    handleOnChange,
  } = useSearchPatients(debounceTime);

  const CustomPaper = (props: PaperProps) => {
    if (hideOptions) {
      return null;
    }
    return (
      <Paper {...props}>
        {loading && (
          <ListLoadingOverlay>
            <CircularProgress />
          </ListLoadingOverlay>
        )}
        {props.children}
        {data?.patientsSearch.hasMoreResults && (
          <Box sx={{ py: '6px', px: '16px', borderTop: '1px solid #E0E0E0' }}>
            <span>
              Results may be limited. Refine your search to find the desired
              patient.
            </span>
          </Box>
        )}
      </Paper>
    );
  };

  const renderNoOptionsText = () => {
    if (hideOptions && !data) {
      return null;
    }
    if (data?.patientsSearch.patients.length === 0) {
      return 'No results found';
    }
  };

  return (
    <Autocomplete
      aria-label="search"
      noOptionsText={renderNoOptionsText()}
      options={data?.patientsSearch.patients ?? []}
      getOptionLabel={(option) => option.fullIdentification}
      inputValue={inputValue}
      value={null}
      renderInput={(params) => {
        const {
          InputLabelProps: _InputLabelProps,
          InputProps,
          ...rest
        } = params;

        return (
          <StyledInputBase
            placeholder="Search patient"
            {...InputProps}
            {...rest}
          />
        );
      }}
      filterOptions={(x) => x}
      autoHighlight
      clearOnBlur={false}
      fullWidth
      onInputChange={handleInputChange}
      PaperComponent={CustomPaper}
      popupIcon={null}
      onChange={handleOnChange}
    />
  );
};

export default Search;
