import { ServerError } from '@apollo/client';
import { ErrorHandler } from '@apollo/client/link/error';
import { IFeedbackModal } from 'components/layout/FeedbackModal';
import { GraphQLFormattedError } from 'graphql';
import { logError } from 'utils/logError';
import { tooManyRequestsFeedbackModalProps } from './getAxiosTooManyRequestsFeedbackModalProps';

const handledErrors = ['removeExam', 'updateExam', 'validateExam'];
const isHandledError = (error?: GraphQLFormattedError) => {
  return handledErrors.some(
    (handledError) => error?.path?.includes(handledError),
  );
};

// Log any GraphQL errors or network error that occurred
export const apolloErrorHandler =
  (
    openFeedbackModal: (props: IFeedbackModal) => void,
    openSessionExpiryModal: () => void,
  ): ErrorHandler =>
  ({ graphQLErrors, networkError }) => {
    if (networkError) {
      if (networkError && (networkError as ServerError).statusCode === 401) {
        openSessionExpiryModal();
        return;
      }

      if (networkError.message === 'Socket closed') {
        logError({
          error: `[Network error]: ${networkError.message}`,
          ignoreSentry: true,
        });
        return;
      }

      logError({
        error: `[Network error]: ${JSON.stringify(networkError)}`,
      });

      if (networkError && (networkError as ServerError).statusCode === 429) {
        openFeedbackModal(tooManyRequestsFeedbackModalProps);
        return;
      }
    }

    const errors = graphQLErrors?.map(
      ({ message, locations, path }) =>
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${JSON.stringify(path)}`,
    );

    errors?.forEach((error) => logError({ error }));

    const is429GraphQLError = !!graphQLErrors?.some(
      (gqlError) => gqlError.extensions?.statusCode === 429,
    );

    if (is429GraphQLError) {
      openFeedbackModal(tooManyRequestsFeedbackModalProps);
      return;
    }

    if (!isHandledError(graphQLErrors?.[0])) {
      openFeedbackModal({
        type: 'error',
        title: 'Request error',
        message: 'Please try again later.',
      });
    }
  };
