/**
 * Sanitizes the search input by removing special characters,
 * normalizing whitespace, and trimming the result.
 *
 * @param {string} input - The raw search string to be sanitized
 * @returns {string} The sanitized search string with special characters removed
 * and whitespace normalized
 */

export const sanitizeSearchInput = (input: string): string => {
  return input
    .replace(/[^a-zA-ZÀ-ÿ0-9 -]/g, '')
    .replace(/\s+/g, ' ')
    .trim();
};
