import { callGetCdnSignedCookie } from 'config/cdnSignedCookie.api';
import { CDN_EXPIRATION_LOCAL_STORAGE } from 'constants/api';
import { useEffect } from 'react';
import { SessionUser } from './sessionProvider.types';

// 30 seconds
const EXPIRATION_CHECK_FREQUENCY = 1000 * 30;

const getCdnCookie = (user?: SessionUser) => {
  if (user && !(process.env.REACT_APP_ENV === 'local')) {
    callGetCdnSignedCookie();
  }
};

const shouldRenewCdnCookie = (): boolean => {
  const expirationTimestamp = localStorage.getItem(
    CDN_EXPIRATION_LOCAL_STORAGE,
  );

  // Convert expirationTimestamp to a number and check if is a valid number
  const isExpirationTimestampValid =
    expirationTimestamp && !Number.isNaN(+expirationTimestamp);

  if (!isExpirationTimestampValid) {
    return true;
  }

  const almostExpiredDate = +expirationTimestamp - EXPIRATION_CHECK_FREQUENCY;

  return almostExpiredDate <= Date.now();
};

export const useCdnSessionCookie = (user?: SessionUser) => {
  useEffect(() => {
    // When we get the user the first time
    if (user) {
      getCdnCookie(user);

      const intervalId = setInterval(() => {
        if (shouldRenewCdnCookie()) {
          getCdnCookie(user);
        }
      }, EXPIRATION_CHECK_FREQUENCY);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [user]);
};
