import axios from 'axios';

import { PREDICTION_API_URL } from 'constants/api';
import { DEEP_CAPSULE_MODEL } from 'constants/products';
import { logError } from 'utils/logError';
import { IFilterTabProps } from '../examPanel.types';

export const predictionsRequest = axios.create({
  baseURL: `${PREDICTION_API_URL}/exam`,
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: process.env.REACT_APP_AXIOS_INCLUDE_CREDENTIALS === 'true',
});

export const onFilterSubmit = async ({
  values,
  examId,
  institutionId,
}: IFilterTabProps) => {
  const { filters } = values;

  const filtersData = {};

  if (filters) {
    Object.keys(filters).forEach((key) => {
      const children = filters[key].children;
      if (filters[key].value) {
        if (children) {
          const childrenToFilter = Object.keys(children).map(
            (childKey) => childKey,
          );
          Object.assign(filtersData, { [key]: childrenToFilter });
        }
      } else if (children) {
        const childrenToFilter = Object.keys(children).filter(
          (childKey) => children[childKey].value,
        );
        if (filtersData[key as keyof typeof filtersData]) {
          Object.assign(filtersData, {
            [key]: (
              filtersData[key as keyof typeof filtersData] as string[]
            ).concat(childrenToFilter),
          });
        } else if (!!childrenToFilter.length) {
          Object.assign(filtersData, { [key]: childrenToFilter });
        }
      }
    });
  }

  const formData = {
    data: {
      ...filtersData,
      cleansing: 'none', // filters.cleansing
    },
  };

  try {
    return predictionsRequest.post(`/predict`, formData, {
      headers: {
        'Content-type': 'application/json',
        'Exam-id': examId,
        'Institution-id': institutionId,
        'Model-id': DEEP_CAPSULE_MODEL,
      },
    });
  } catch (error) {
    logError({ error });
    throw error;
  }
};
